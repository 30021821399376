.Profile {
  max-width: 500px;
  margin: 0 auto;
}

.Profile table {
  width: 100%;
}

.Profile-patient-info {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Profile-patient-info table {
  background-color: rgb(231, 234, 243);
}

.Profile-patient-info table tr td {
  padding: 2px 5px;
  word-wrap: break-word;
}

.Profile-patient-info table tr:nth-child(2n + 1) {
  background-color: rgb(206, 212, 232);
}
.Table-subheader {
  background-color: rgb(167, 174, 190);
}

.Profile-patient-info table td:nth-child(2n) {
  border-style: solid;
  border-color: white;
  border-width: 0 0 0 2px;
}

.Profile-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.Profile-logout-button {
  width: 200px;
  background: #1a647e;
  color: white;
  font-size: 16px;
  border: none;
  padding: 5px;
}

.Profile-error-message {
  margin-top: 10px;
  font-size: 14px;
  color: red;
}

/* Component Invited users: */

.Invited-user-list {
  padding: 10px 0px;
}

.Invited-user {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  border-bottom: 1px solid gray;
}

.Invited-user:hover {
  background-color: lightgray;
}

.Invited-user div {
  flex-basis: 100px;
  padding: 2px;
}

.Invited-user span {
  flex: 1;
  padding: 5px;
}
.Invited-user-delete-button,
.Invite-user-button {
  background: #1a647e;
  color: white;
  font-size: 14px;
  border: none;
  padding: 5px;
}
